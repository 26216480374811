<div class="container">
  <div class="header">
    {{ts.l.the_team}}
  </div>
  <div class="aboutContent">
    <div class="headerTitle"> {{ts.l.meet_the_investigator}} </div><br>
    <mat-accordion multi>
      <!-- Neasa Ní Chiaráin  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'" style="padding: 10px;">
          <mat-panel-title>
            <div>
              <div class="name"><b> Neasa Ní Chiaráin </b></div>
              <img src="assets/img/neasa.jpg" class="neasaImg" alt="Neasa Ní Chiaráin">
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <div class="bio" innerHTML={{ts.l.neasa1}}> </div>
          </mat-panel-description>
        </mat-expansion-panel-header>
          <br><div class="bio"> {{ts.l.neasa2}} <br>
                <br><ul> 
                  <li> {{ts.l.neasa2_point1}} </li>
                  <li> {{ts.l.neasa2_point2}} </li>
                </ul>
          </div>
          <div class="bio">
            {{ts.l.neasa3}}
          </div>
          <div class="bio"> <br>
            {{ts.l.neasa4}}<br>
                <br><ul> 
                  <li> {{ts.l.neasa4_point1}} </li>
                  <li> {{ts.l.neasa4_point2}}</li>
                  <li> {{ts.l.neasa4_point3}}</li>
                  <li> {{ts.l.neasa4_point4}}</li>
                </ul>
          </div>
          <div class="bio">
            {{ts.l.neasa5}} <br>
                <br><ul>
                  <li> {{ts.l.neasa5_point1}} </li>
                  <li> {{ts.l.neasa5_point2}} </li>
                  <li> {{ts.l.neasa5_point3}} </li>
                </ul>
          </div>
          <div class="bio">
            {{ts.l.neasa6}}
          </div>
          <div class="bio" innerHTML={{ts.l.neasa7}}></div>
      </mat-expansion-panel>
    </mat-accordion>
    
    <!-- Developers -->
    <div class="headerTitle" style="padding-top:20px" innerHTML={{ts.l.meet_the_developers}}></div><br>
    <!-- Madeleine Comtois  -->
    <mat-card>
      <div class="developerCard">
      <mat-card-content>
        <div>
          <div class="name"><b>Madeleine Comtois</b></div>
          <img src="assets/img/maddie.jpg" class="maddieImg" alt="Madeleine Comtois">
        </div>
      </mat-card-content>
      <mat-card-content>
        <div class="bio" innerHTML={{ts.l.maddie_bio}}></div>
      </mat-card-content>
      </div>
    </mat-card><br>
    <!-- Oisín Nolan -->
    <mat-card>
      <div class="developerCard">
      <mat-card-content>
        <div class="bio" innerHTML={{ts.l.oisin_bio}}></div>
      </mat-card-content>
      <mat-card-content>
        <div>
          <div class="name"><b>Oisín Nolan</b></div>
          <img src="assets/img/oisin.png" class="oisinImg" alt="Oisín Nolan">
        </div>
      </mat-card-content>
      </div>
    </mat-card><br>
    <!-- Sarah Rooney -->
    <mat-card>
      <div class="developerCard">
      <mat-card-content>
        <div>
          <div class="name"><b>Sarah Rooney</b></div>
          <img src="assets/img/sarah.png" class="sarahImg" alt="Sarah Rooney">
        </div>
      </mat-card-content>
      <mat-card-content>
        <div class="bio" innerHTML={{ts.l.sarah_bio}}></div>
      </mat-card-content>
      </div>
    </mat-card>

    <!-- Artists -->
    <div class="headerTitle" style="padding-top:20px"> {{ts.l.meet_the_artist}} </div><br>
    <!-- Anna Giovannini  -->
    <mat-card>
      <div class="developerCard">
      <mat-card-content>
        <div>
          <div class="name"><b>Anna Giovannini</b></div>
          <img src="assets/img/anna.png" class="annaImg" alt="Anna Giovannini">
        </div>
      </mat-card-content>
      <mat-card-content>
        <div class="bio">{{ts.l.anna_bio}}</div>
      </mat-card-content>
      </div>
    </mat-card>
    
    <!-- Abair  -->
    <div class="headerTitle" style="padding-top:20px"> {{ts.l.meet_the_abair_team}} </div><br>
    <mat-card>
      <div>
        <mat-card-content>
          <div class="bio" innerHTML={{ts.l.abair_bio}}></div>
        </mat-card-content>
        <mat-card-content>
          <div style="text-align: center;">
            <img src="assets/img/an_fhoireann_sa_tsaotharlann2019.jpg" class="teamImg" alt="Abair team">
          </div>
        </mat-card-content>
        <mat-card-content>
          <div style="text-align: center;"><i>{{ts.l.abair_pic_description}}</i></div>
        </mat-card-content>
      </div>
    </mat-card>
  </div>
</div>