<div class="container">
  <div class="header">
    <strong>{{ts.message('report_an_issue')}}</strong>
  </div>
  <!-- Report An Issue Prose-->
  <mat-card class="sponsorCard">
    <p>
    {{ts.message('report_an_issue_prose')}}
    <a href='mailto:scealai.info@gmail.com'>scealai.info@gmail.com</a>.
    </p>
    <p>
    {{ts.message('report_an_issue_ps')}}
    </p>
  </mat-card>
</div>
