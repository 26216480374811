<div class="container">
    <div class="header">
      {{ts.l.what_is_taidhgin_title}}
    </div>
    <div class="aboutContent">
      <div innerHTML={{ts.l.about_taidhgin1}}></div>
      <br/>
      <div innerHTML={{ts.l.about_taidhgin2}}></div>
      <br/>
      <div innerHTML={{ts.l.about_taidhgin3}}></div>
      <br/>
      <div innerHTML={{ts.l.about_taidhgin4}}></div>
    </div>
  </div>
