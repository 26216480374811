<div class="container">
  <div class="header"> {{ts.l.user_guides}} </div><br>
  <mat-card style="text-align:left;">
    <mat-card-header>
      <mat-card-title> {{ts.l.download_user_guide}} </mat-card-title>
      <mat-card-subtitle> {{ts.l.download_user_guide_by_clicking_link}} </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <a href="assets/pdf/User_guide.pdf" class="greenBtn" download> {{ts.l.user_guide}} <i class="fas fa-download"></i></a>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card style="text-align:left;">
    <mat-card-header>
      <mat-card-title> {{ts.l.video_tutorial}} </mat-card-title>
      <mat-card-subtitle> {{ts.l.watch_following_instructional_video}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <iframe frameborder="0" allowfullscreen
      src="https://www.youtube.com/embed/BOn4lNYAmwA?autoplay=0&mute=0">
      </iframe>
    </mat-card-content>
  </mat-card>
</div>
