<div class="container">
  <div class="header">
    {{ts.l.about_an_scealai}}
    <div class="quote">
      "{{ts.l.about_quote}}" <br/> - <span class="author">{{ts.l.about_quote_author}}</span>
    </div>
  </div>
  <div class="aboutContent">
    <mat-card style="text-align:left;">
      <mat-card-header>
        <mat-card-title> {{ts.l.what_is_an_scealai}} </mat-card-title>
      </mat-card-header>
      <mat-card-content class="textContent">
        {{ts.l.about_content_text}}
        <br><br>
        {{ts.l.about_content_text_academic}}
      </mat-card-content>
    </mat-card>
    <br><br>

    <div class="paperHeader"> {{ts.l.awards}} </div>
    <mat-card style="text-align:left;">
      <mat-card-header>
        <mat-card-title> Bronnadh An Séala Eorpach Teanga 2022 ar An Scéalaí </mat-card-title>
      </mat-card-header>
      <mat-card-content class="textContent">
        <img src="assets/img/ELL_Certificate.jpg" alt="ELL Certificate" class="ELLCertificate">
        <div [innerHTML]="ts.l.ell_description_1"></div><br>
        <div [innerHTML]="ts.l.ell_description_2"></div><br>
        <div [innerHTML]="ts.l.ell_description_3"></div><br>
        <div class="ELLPhotos">
          <img src="assets/img/ELL_photo1.jpeg" alt="ELL Photo 1" style="width:25%">
          <img src="assets/img/ELL_photo2.jpeg" alt="ELL Photo 2" style="width:25%">
        </div>
      </mat-card-content>
    </mat-card><br><br>
    <!-- Papers -->
    <div class="paperHeader"> {{ts.l.academic_papers}} </div>
    <mat-accordion multi>
      <mat-expansion-panel >
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <mat-panel-title>
              <div><b> The teacher-in-the-loop: collaboration with the teaching community in the development of an iCALL platform for Irish </b></div>
          </mat-panel-title>
          <mat-panel-description>
            2023 - Madeleine Comtois, Neasa Ní Chiaráin
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="paperDescription">This paper explores the teacher functionality within An Scéalaí, an iCALL platform being developed for Irish. Aiming to foster Irish language learning, the design and development of An Scéalaí has from the outset employed a cooperative approach with educators to optimise its responsiveness to user needs. There is a particular emphasis on corrective feedback at this current stage of development as we posit that administering constructive corrective feedback to learners will be the cornerstone of its pedagogical effectiveness and user satisfaction. The efficacy of iCALL platforms in promoting endangered languages, such as Irish, is contingent upon the active involvement of the associated language community. This work presents the findings of a recent questionnaire conducted as part of the ongoing initiative to engage educators and developers in a collective effort towards language revitalisation. We sought to gain an overview of teachers' experiences teaching Irish and their impressions of the An Scéalaí platform, introducing new functionality they would not have previously encountered. The results give us insights into the current situation of technology use in classrooms (post-Covid). It reinforces the importance of having the teacher-in-the-loop at every stage of iCALL development to ensure useful applications are built for the wider teacher community. </div>
          <br>
          <a href="assets/pdf/EuroCALL_2023.pdf" class="fileDownloadBtn" target="_blank">{{ts.l.download}} PDF <i class="fas fa-download"></i></a>
      </mat-expansion-panel>
      <mat-expansion-panel >
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <mat-panel-title>
              <div><b> An Bat Mírialta: Stateful Development of an Irregular Verb Bot for Irish </b></div>
          </mat-panel-title>
          <mat-panel-description>
            2023 - John Sloan, Neasa Ní Chiaráin
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="paperDescription">This paper outlines a system description and small-scale us- ability study of An Bat Mírialta - ('The Irregular Verb Bot')- a newly developed educational application embedded within the Irish intelligent-Computer Assisted Language Learning (iCALL) platform An Scéalaí (www.abair.ie/scealai). An Bat Mírialta is being developed to aid learners in their mastery of the 11 irregular verbs of Irish. The work is presented in the context of a novel conceptual framework for CALL artefact design, implementation and evaluation - LeTAT. Challenges and concerns faced in developing an educational technology for a low-resource, endangered language are also described.</div>
          <br>
          <a href="assets/pdf/Bat_Mirialta_SLaTE_2023.pdf" class="fileDownloadBtn" target="_blank">{{ts.l.download}} PDF <i class="fas fa-download"></i></a>
      </mat-expansion-panel>
      <mat-expansion-panel >
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <mat-panel-title>
              <div><b> Using Speech and NLP Resources to build an iCALL platform for a 
                minority language, the story of An Scéalaí, the Irish experience to date </b></div>
          </mat-panel-title>
          <mat-panel-description>
            2022 - Neasa Ní Chiaráin, Oisín Nolan, Madeleine Comtois, Neimhin Robinson Gunning, Harald Berthelsen, Ailbhe Ní Chasaide
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="paperDescription">This paper describes how emerging linguistic resources and technologies can be used to build a language learning platform for Irish, an endangered language. This platform, An Scéalaí, harvests learner corpora-a vital resource both to study the stages of learners’ language acquisition and to guide future platform development. A technical description of the platform is provided, including details of how different speech technologies and linguistic resources are fused to provide a holistic learner experience. The active continuous participation of the community, and platform evaluations by learners and teachers, are discussed.</div>
          <br>
          <a href="assets/pdf/ComputEL.pdf" class="fileDownloadBtn" target="_blank">{{ts.l.download}} PDF <i class="fas fa-download"></i></a>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <mat-panel-title>
              <div><b> Celtic CALL: strengthening the vital role of education for language transmission </b></div>
          </mat-panel-title>
          <mat-panel-description>
            2022 - Neasa Ní Chiaráin, Madeleine Comtois, Oisín Nolan, Neimhin Robinson Gunning, John Sloan, Harald Berthelsen, Ailbhe Ní Chasaide
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="paperDescription"> In this paper, we present the Irish language learning platform, An Scéalaí, an intelligent Computer-Assisted Language Learning (iCALL) system which incorporates speech and language technologies in ways that promote the holistic development of the language skills-writing, listening, reading, and speaking. The technologies offer the advantage of extensive feedback in spoken and written form, enabling learners to improve their production. The system works equally as a classroom-based tool and as a standalone platform for the autonomous learner. Given the key role of education for the transmission of all the Celtic languages, it is vital that digital technologies be harnessed to maximise the effectiveness of language teaching/learning. An Scéalaí has been used by large numbers of learners and teachers and has received very positive feedback. It is built as a modular system which allows existing and newly emerging technologies to be readily integrated, even if those technologies are still in development phase. The architecture is largely language-independent, and as an open-source system, it is hoped that it can be usefully deployed in other Celtic languages.</div>
          <br><br>
          <a href="assets/pdf/CelticCALL.pdf" class="fileDownloadBtn" target="_blank">{{ts.l.download}} PDF <i class="fas fa-download"></i></a>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
          <mat-panel-title>
              <div><b> An Scéalaí: autonomous learners harnessing speech and language technologies </b></div>
          </mat-panel-title>
          <mat-panel-description>
            2019 - Neasa Ni Chiarain, Ailbhe Ni Chasaide
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="paperDescription"> This paper presents an autonomous language learning platform which has speech and language technology at its core. An Scealaí ('the Storyteller') is a web-based interactive iCALL system that allows learners to compose their own text and correct it by listening to the text spoken with synthetic voices (aural proofing) and by responding to NLP prompts that detect errors. All learner interactions are logged and monitored, allowing researchers to observe the learning processes. A pilot study by 14 learners of Irish in the US was conducted to explore the efficacy of the corrective mechanisms currently implemented in the platform. The results of the pilot study indicate that both mechanisms currently on offer were effective in the development of writing skills but work in rather different ways. Aural proofing appears to generate an immediate corrective response which indicates that it is serving both to develop awareness of specific phonological contrasts as well as basic phonic rules of the language. In the case of the NLP prompts, corrections were also made, although the data suggest that this is a slower process and that learners may, at least initially, merely be satisfying the system's prompts rather than discovering the grammatical basis of the correction. The pilot has been useful in pointing towards future directions for platform development that can take the needs of this type of adult autonomous learner into account.</div>
          <br><br>
          <a href="assets/pdf/paper1.pdf" class="fileDownloadBtn" target="_blank">{{ts.l.download}} PDF <i class="fas fa-download"></i></a>
      </mat-expansion-panel>
    </mat-accordion>
    <br><br>
    <!-- Posters -->
    <div class="paperHeader"> {{ts.l.posters}} </div>
    <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
        <mat-panel-title>
            <div><b> An Scéalaí - the intelligent-CALL platform </b></div>
        </mat-panel-title>
        <mat-panel-description>
          2023 - Neasa Ní Chiaráin, Madeleine Comtois, Harald Berthelsen, John Sloan, Neimhin Robinson Gunning, Oisín Nolan
        </mat-panel-description>
      </mat-expansion-panel-header>
        <br><br>
        <a href="assets/pdf/UK_Speech_Scealai_Poster.pdf" class="fileDownloadBtn" target="_blank">{{ts.l.download}} PDF <i class="fas fa-download"></i></a>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
        <mat-panel-title>
            <div><b> An Bat Mírialta - The Irregular Verb Bot </b></div>
        </mat-panel-title>
        <mat-panel-description>
          2023 - Neasa Ní Chiaráin, John Sloan, Madeleine Comtois, Harald Berthelsen, Neimhin Robinson Gunning, Oisín Nolan, Oscar Maharg Bravo
        </mat-panel-description>
      </mat-expansion-panel-header>
        <br><br>
        <a href="assets/pdf/UK_Speech_Bat_Mirialta_Poster.pdf" class="fileDownloadBtn" target="_blank">{{ts.l.download}} PDF <i class="fas fa-download"></i></a>
    </mat-expansion-panel>
  </mat-accordion>
  <br>
  <img src="assets/img/ACL_group_photo.png" alt="ACL poster group photo" class="ELLCertificate" style="width:40%" >
  <br><br>
  </div>
</div>