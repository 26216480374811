<div class="container">
  <div class="header">
      {{ts.l.fios_title}}
    <div class="quote">{{ts.l.fios_welcome}}</div>
  </div>

  <img class="fiosLogo" src="assets/img/fios_logo_black.png" />

  <div class="aboutContent">
    <mat-card style="text-align: left">
      <mat-card-header>
        <mat-card-title> Céad míle fáilte! </mat-card-title>
      </mat-card-header>
      <mat-card-content class="textContent">
        {{ts.l.fios_intro}}
      </mat-card-content>
    </mat-card>
    <br /><br />

    <mat-card style="text-align: left">
      <mat-card-header>
        <mat-card-title> {{ts.l.our_mission}} </mat-card-title>
      </mat-card-header>
      <mat-card-content class="textContent">
        {{ts.l.fios_mission}}
      </mat-card-content>
    </mat-card>
    <br /><br />

    <mat-card style="text-align: left">
      <mat-card-header>
        <mat-card-title> {{ts.l.what_we_do}} </mat-card-title>
      </mat-card-header>
      <mat-card-content class="textContent">
        {{ts.l.what_we_do_1}}
        <br /><br />
        {{ts.l.what_we_do_2}}
        <br /><br />
        {{ts.l.what_we_do_3}}
      </mat-card-content>
    </mat-card>
    <br /><br />

    <mat-card style="text-align: left">
      <mat-card-header>
        <mat-card-title> {{ts.l.key_CALL_for_irish_initiatives}} </mat-card-title>
      </mat-card-header>
      <mat-card-content class="textContent">
        <ol>
          <li>
            <b>{{ts.l.an_scealai_interactive_learning_platform}}:</b> {{ts.l.key_CALL_1}}
          </li>
          <li>
            <b>An Bat Mírialta:</b> {{ts.l.key_CALL_2}}
          </li>
          <li>
            <b>Cén Scéal:</b> {{ts.l.key_CALL_3}}
          </li>
          <li>
            <b>LARA:</b> {{ts.l.key_CALL_4}}
          </li>
          <li>
            <b>{{ts.l.dictogloss}}:</b> {{ts.l.key_CALL_5}}
          </li>
          <li>
            <b>Taidhgín:</b> {{ts.l.key_CALL_6}}
          </li>
          <li>
            <b>An Corpas Cliste:</b> {{ts.l.key_CALL_7}}
          </li>
        </ol>
      </mat-card-content>
    </mat-card>
    <br /><br />

    <mat-card style="text-align: left">
      <mat-card-header>
        <mat-card-title>
          {{ts.l.core_technologies_employed_for_CALL}}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content class="textContent">
        <ol>
          <li>
            <b>{{ts.l.text_to_speech_synthesis}}:</b> {{ts.l.core_technologies_1}}
          </li>
          <li>
            <b>{{ts.l.speech_recognition_for_irish}}:</b> {{ts.l.core_technologies_2}}
          </li>
          <li>
            <b>{{ts.l.nlp_for_irish}}:</b> {{ts.l.core_technologies_3}}
          </li>
        </ol>
      </mat-card-content>
    </mat-card>
    <br /><br />

    <mat-card style="text-align: left">
      <mat-card-header>
        <mat-card-title> {{ts.l.collaboration_and_outreach}} </mat-card-title>
      </mat-card-header>
      <mat-card-content class="textContent">
        {{ts.l.collaboration_and_outreach_text}}
      </mat-card-content>
    </mat-card>
    <br /><br />

    <mat-card style="text-align: left">
      <mat-card-header>
        <mat-card-title> {{ts.l.join_us}} </mat-card-title>
      </mat-card-header>
      <mat-card-content class="textContent">
        {{ts.l.join_us_text}}
      </mat-card-content>
      <mat-card-header>
        <mat-card-title> {{ts.l.contact_us}} </mat-card-title>
      </mat-card-header>
      <mat-card-content class="textContent">
        <b>{{ts.l.address}}</b>: FIoS Research Group, The Phonetics & Speech Lab., Trinity
        College Dublin, College Green, Dublin 2, Ireland
        <br /><br />
        <b>{{ts.l.email}}</b>: <a href='mailto:scealai.info@gmail.com'>scealai.info@gmail.com</a>
      </mat-card-content>
    </mat-card>

    <br /><br />

    <mat-card style="text-align: left">
      <mat-card-header>
        <mat-card-title> {{ts.l.updates_and_news}} </mat-card-title>
      </mat-card-header>
      <mat-card-content class="textContent">
        {{ts.l.updates_and_news_1}}
        <br /><br />
        <i>
          {{ts.l.updates_and_news_2}}
        </i>
      </mat-card-content>
    </mat-card>
    <br /><br />
  </div>
</div>
