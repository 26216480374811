<div class="container">
    <div class="header" innerHTML={{ts.l.technology_in_an_scealai}}></div>
    <div class="content">
        <div class="section">
            • {{ts.l.abair_technology_title}} -<a href="https://www.abair.tcd.ie" class="siteLink" target="_blank">Abair.ie</a>
        </div>
        <div class="section">
            • {{ts.l.gramadoir_technology_title}} -<a href="https://cadhan.com/gramadoir/foirm.html" class="siteLink" target="_blank">Cadhan.com/gramadoir</a>
        </div>
        <div class="section">
            • {{ts.l.NLP_title}} -<a href="https://www.scss.tcd.ie/~uidhonne/irish.utf8.htm" class="siteLink" target="_blank">Scss.tcd.ie/uidhonne/irish</a>
        </div>
        <div class="section">
            • {{ts.l.LARA_technology_title}} -<a href="https://www.unige.ch/callector" class="siteLink" target="_blank">Unige.ch/callector</a>
        </div>
    </div>
</div>
  