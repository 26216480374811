<div class="container">
  <div class="header">
    {{ts.l.resources}}
  </div>
  <div class="aboutContent">
    <div class="headerTitle"> {{ts.l.story_examples}} </div><br>
    <div class="headerTitle"> {{ts.l.story_bank_growing}} </div><br><br>
    <div style="font-size: 15pt;"> {{ts.l.higher_level}} </div>
    <!-- Gweedore (Ulster) stories-->
    <div class="dialectTitle"> {{ts.l.gweedore}} </div>
    <mat-accordion multi>
      <!-- Balor -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/1_balor.png" class="storyImg" alt="Balor">
          </mat-panel-title>
          <mat-panel-description class="panelDescription">
            Balor
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.balor1}} </div> <br>
            <div> {{ts.l.balor2}} </div> <br>
            <div> {{ts.l.balor3}} </div>
          </div>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Balor_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Balor_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Balor_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- Beatha na Féinne -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/beatha02_color.png" class="storyImg" alt="Beatha na Féinne">
          </mat-panel-title>
          <mat-panel-description class="panelDescription">
            Beatha na Féinne 
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.beathna_na_feinne1}} </div> <br>
            <div> {{ts.l.beathna_na_feinne2}}</div>
          </div>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Beatha_na_Feinne_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Beatha_na_Feinne_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Beatha_na_Feinne_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- Colorado -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/logo-S.png" class="storyImg" alt="Colorado">
          </mat-panel-title>
          <mat-panel-description class="panelDescription">
            Colorado
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.colorado1}} </div> <br>
            <div> {{ts.l.colorado2}}</div>
          </div>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Colorado_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Colorado_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Colorado_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Druma Mór -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/logo-S.png" class="storyImg" alt="Druma Mór">
          </mat-panel-title>
          <mat-panel-description class="panelDescription">
            An Druma Mór
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.druma_mor1}}</div> <br>
            <div> {{ts.l.druma_mor2}} </div> <br>
            <div> <i> {{ts.l.druma_mor3}}</i> </div> <br>
            <div> {{ts.l.druma_mor4}} </div> <br>
            <div> {{ts.l.druma_mor5}} </div> <br>
            <div> {{ts.l.druma_mor6}} </div>
          </div>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Druma_M%C3%B3r_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Druma_M%C3%B3r_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Druma_M%C3%B3r_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      
    </mat-accordion>
    
    <!-- Mumhan (Munster) stories-->
    <div class="dialectTitle"> {{ts.l.mumhan}} </div>
    <mat-accordion multi>
      <!-- Mar a Baisteadh Fionn  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/fionn01_colors.jpg" class="storyImg" alt="Mar a Baisteadh Fionn">
          </mat-panel-title>
          <mat-panel-description class="panelDescription">
            Mar a Baisteadh Fionn
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.mar_a_baisteadh_fionn1}} </div> <br>
            <div> {{ts.l.mar_a_baisteadh_fionn2}} </div> <br>
            <div> {{ts.l.mar_a_baisteadh_fionn3}} </div> <br>
            <div> {{ts.l.mar_a_baisteadh_fionn4}} </div>
          </div>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Mar_A_Baisteadh_Fionn_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Mar_A_Baisteadh_Fionn_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Mar_A_Baisteadh_Fionn_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- Fairceallach Fhinn Mhic Chumhaill -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/Fairceallach.png" class="storyImg" alt="Fairceallach Fhinn Mhic Chumhaill"> 
          </mat-panel-title>
          <mat-panel-description class="panelDescription">
             Fairceallach Fhinn Mhic Chumhaill
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.fairceallach1}} </div> <br>
            <div> {{ts.l.fairceallach2}} </div> <br>
            <div> {{ts.l.fairceallach3}} </div>
          </div>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Fairceallach_Fhinn_Mhic_Chumhaill_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Fairceallach_Fhinn_Mhic_Chumhaill_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Fairceallach_Fhinn_Mhic_Chumhaill_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- Pósadh ar an mBlascaod Mór -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/posadh01.png" class="storyImg" alt="Pósadh ar an mBlascaod Mór">
          </mat-panel-title>
          <mat-panel-description class="panelDescription">
            Pósadh ar an mBlascaod Mór
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.posadh_ar_an_mblascaod_mor1}} </div> <br>
            <div> {{ts.l.posadh_ar_an_mblascaod_mor2}} </div>
          </div>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Bhlascaod_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Bhlascaod_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Bhlascaod_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
    
    <div *ngIf="!auth.isLoggedIn()" class="signIn"> {{ts.l.sign_in_to_view_more}} </div>
    
    <!-- Leaving Cert stories-->
    <div class="dialectTitle"> {{ts.l.leaving_cert_stories}} </div>
    <!--<div *ngIf="!auth.isLoggedIn()" style="padding-left:30px"> {{ts.l.sign_in_to_view_more}} </div>-->
    <mat-accordion multi *ngIf="auth.isLoggedIn()">
      <!-- Oisín i dTír na nÓg  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/oisin02_color.png" class="storyImg" alt="Mar a Baisteadh Fionn">
          </mat-panel-title>
          <mat-panel-description class="panelDescription">
            Oisín i dTír na nÓg
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.oisin_i_dTir_na_nOg1}} </div> <br>
            <div> {{ts.l.oisin_i_dTir_na_nOg2}} </div> <br>
            <div> {{ts.l.oisin_i_dTir_na_nOg3}} </div>
          </div>
        <mat-action-row class="moreResources">
            <div><a class="viewStoryBtn" href="https://tuairisc.ie/pros-oisin-i-dtir-na-nog/" target="_blank" rel="noopener noreferrer"> {{ts.l.more_resources}} </a> </div>
            <div class="dialectContainer">         
              <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
              <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Oisin_i_Dtir_na_Nog_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
              <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Oisin_i_Dtir_na_Nog_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
              <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Oisin_i_Dtir_na_Nog_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
            </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- Dís -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/dis.png" class="storyImg" alt="Fairceallach Fhinn Mhic Chumhaill"> 
          </mat-panel-title>
          <mat-panel-description class="panelDescription panelFlex">
            <div>Dís</div>
            <div>le Siobhán Ní Shúilleabháin</div>
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.dis1}} </div> <br>
            <div> {{ts.l.dis2}} </div>
          </div>
        <mat-action-row class="moreResources">
          <div><a class="viewStoryBtn" href="https://tuairisc.ie/pros-dis/" target="_blank" rel="noopener noreferrer"> {{ts.l.more_resources}} </a> </div>
          <div class="dialectContainer">      
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Dis_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Dis_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Dis_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Gnáthrud -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/an_gnathrud.png" class="storyImg" alt="Pósadh ar an mBlascaod Mór">
          </mat-panel-title>
          <mat-panel-description class="panelDescription panelFlex">
            <div>An Gnáthrud</div>
            <div>le Deirdre Ní Ghrianna</div>
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.an_gnathrud}} </div>
          </div>
        <mat-action-row class="moreResources">
          <div><a class="viewStoryBtn" href="https://tuairisc.ie/pros-an-gnathrud/" target="_blank" rel="noopener noreferrer"> {{ts.l.more_resources}} </a> </div>
          <div class="dialectContainer">      
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Gnathrud_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Gnathrud_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Gnathrud_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- Hurlamaboc -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/hurmalaboc.png" class="storyImg" alt="Pósadh ar an mBlascaod Mór">
          </mat-panel-title>
          <mat-panel-description class="panelDescription panelFlex">
            <div>Hurlamaboc</div>
            <div>le hÉilís Ní Dhuibhne</div>
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.hurlamboc}} </div>
          </div>
        <mat-action-row class="moreResources">
          <div><a class="viewStoryBtn" href="https://tuairisc.ie/pros-hurlamaboc/" target="_blank" rel="noopener noreferrer"> {{ts.l.more_resources}} </a> </div>
          <div class="dialectContainer">      
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Hurlamaboc_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Hurlamaboc_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Hurlamaboc_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- Seal i Neipeal -->
      <mat-expansion-panel>
        <mat-expansion-panel-header [collapsedHeight]="'200px'" [expandedHeight]="'200px'">
          <mat-panel-title>
            <img src="assets/img/neipeal02_color.png" class="storyImg" alt="Pósadh ar an mBlascaod Mór">
          </mat-panel-title>
          <mat-panel-description class="panelDescription panelFlex">
            <div>Seal i Neipeal</div>
            <div>le Cathal Ó Searcaigh</div>
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="panelContent">
            <div> {{ts.l.seal_i_neipeal}} </div>
          </div>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Seal_i_Neipeal_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Seal_i_Neipeal_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Seal_i_Neipeal_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
    
    <!-- Other stories-->
    <div class="dialectTitle"> {{ts.l.other_stories}} </div>
    <!--<div *ngIf="!auth.isLoggedIn()" style="padding-left:30px"> {{ts.l.sign_in_to_view_more}} </div>-->
    <mat-accordion multi *ngIf="auth.isLoggedIn()">
      <!-- An Seilide agus An Míol Mór  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>        
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Seilide agus An Míol Mór
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Seilide_agus_an_Miol_Mor_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Seilide_agus_an_Miol_Mor_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Seilide_agus_an_Miol_Mor_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Prionsa Beag  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Prionsa Beag
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://abair.ie/lara/compiled/LARA_An_Prionsa_Beag_nnc_dnnvocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://abair.ie/lara/LARA_Cinnin_Oir_agus_na_Tri_Bhear_pmg_dnnvocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://abair.ie/lara/compiled/LARA_An_Prionsa_Beag_anb_dnnvocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
    
    <!-- Simple versions of old stories-->
    <div class="dialectTitle"> {{ts.l.simple_versions_old_stories}} </div>
    <!--<div *ngIf="!auth.isLoggedIn()" style="padding-left:30px"> {{ts.l.sign_in_to_view_more}} </div>-->
    <mat-accordion multi *ngIf="auth.isLoggedIn()">
      <!-- Cinnín Óir agus na Trí Bhéar  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            Cinnín Óir agus na Trí Bhéar
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Cinnin_Oir_agus_na_Tri_Bhear_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Cinnin_Oir_agus_na_Tri_Bhear_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Cinnin_Oir_agus_na_Tri_Bhear_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- Na Trí Mhuicín  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            Na Trí Mhuicín
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Na_Tri_Mhuicin_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Na_Tri_Mhuicin_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Na_Tri_Mhuicin_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- Cochaillín Dearg  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            Cochaillín Dearg
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Cochaillin_Dearg_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Cochaillin_Dearg_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Cochaillin_Dearg_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Píobaire Breac  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Píobaire Breac
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Piobaire_Breac_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Piobaire_Breac_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Piobaire_Breac_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Lacha Bheag Ghránna  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Lacha Bheag Ghránna
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Lacha_Bheag_Ghranna_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Lacha_Bheag_Ghranna_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Lacha_Bheag_Ghranna_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- Na Trí Ghabhar Chliste  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            Na Trí Ghabhar Chliste
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Na_Tr%C3%AD_Ghabhar_Chliste_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Na_Tr%C3%AD_Ghabhar_Chliste_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Na_Tr%C3%AD_Ghabhar_Chliste_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Tornapa Mór Millteach  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Tornapa Mór Millteach
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Tornapa_M%C3%B3r_Millteach_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Tornapa_M%C3%B3r_Millteach_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Tornapa_M%C3%B3r_Millteach_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- Aesop Fables -->
    <div class="dialectTitle"> {{ts.l.aesop_fables}} </div>
    <mat-accordion multi *ngIf="auth.isLoggedIn()">
      <!-- An Bhean agus an Madra Rua  -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>        
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Bhean agus an Madra Rua
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="dialectContainer">         
            <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Bhean_agus_An_Madra_Rua_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Bhean_agus_An_Madra_Rua_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
            <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Bhean_agus_An_Madra_Rua_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
          </div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Giorria agus an Toirtís   -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Giorria agus an Toirtís 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Giorria_agus_an_Toirt%C3%ADs_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Giorria_agus_an_Toirt%C3%ADs_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Giorria_agus_an_Toirt%C3%ADs_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Torc Allta agus an Sionnach   -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Torc Allta agus an Sionnach
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
          <!-- <div class="viewStory"><a class="viewStoryBtn" href="" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div> -->
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Torc_Allta_agus_an_Sionnach_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Torc_Allta_agus_an_Sionnach_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Chircín Rua    -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Chircín Rua 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Chirc%C3%ADn_Rua_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Chirc%C3%ADn_Rua_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Chirc%C3%ADn_Rua_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Leon agus an Luchóg    -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Leon agus an Luchóg 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Leon_agus_an_Luch%C3%B3g_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Leon_agus_an_Luch%C3%B3g_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Leon_agus_an_Luch%C3%B3g_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
        </mat-action-row>
      </mat-expansion-panel>
        <!-- Cochailín Dearg    -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            Cochailín Dearg 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Cochaill%C3%ADn_Dearg_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Cochaill%C3%ADn_Dearg_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Cochaill%C3%ADn_Dearg_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Coileach agus an Sionnach   -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Coileach agus an Sionnach
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Coileach_agus_an_Sionnach_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Coileach_agus_an_Sionnach_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Coileach_agus_an_Sionnach_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- Luchóg na Cathrach agus Luchóg na Tuaithe   -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            Luchóg na Cathrach agus Luchóg na Tuaithe 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Luch%C3%B3g_na_Cathrach_agus_Luch%C3%B3g_na_Tuaithe_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Luch%C3%B3g_na_Cathrach_agus_Luch%C3%B3g_na_Tuaithe_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/Luch%C3%B3g_na_Cathrach_agus_Luch%C3%B3g_na_Tuaithe_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
        </mat-action-row>
      </mat-expansion-panel>
      <!-- An Sionnach agus an Corr Réisc   -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description class="panelDescriptionNoPhoto">
            An Sionnach agus an Corr Réisc 
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-action-row>
          <div class="viewStory" style="font-weight:bold;">{{ts.l.view_story}}:</div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Sionnach_agus_an_Corr_R%C3%A9isc_nnc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.munster}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Sionnach_agus_an_Corr_R%C3%A9isc_pmc_nemovocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.connacht}} </a></div>
          <div class="viewStory"><a class="viewStoryBtn" href="https://lara.abair.ie/An_Sionnach_agus_an_Corr_R%C3%A9isc_anb_pipervocabpages/_hyperlinked_text_.html" target="_blank" rel="noopener noreferrer"> {{ts.l.ulster}} </a></div>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
    
  </div>
</div>

<!-- Footer containing logos -->
<div class="footerLARA">
  <div class="footerLogos">
    <div class="copyright"> {{ts.l.published_courtesy_of}} </div>
    <div class="logoPadding"><img class="logoImg" src="assets/img/logo_an_gum.png"></div>
    <div class="logoPadding"><img class="logoImg" src="assets/img/logo_foras_na_gaeilge.png"></div>
  </div>
</div>