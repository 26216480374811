<div class="landingTextContainer">
    <img src="assets/img/logo-landing-resize.png" class="landingImg" alt="An Scéalaí logo">
    <p class="landingText">
      {{ts.l.quote}}
    </p>
    <div class="landingText quoteAuthorText">{{ts.l.author}}</div>
</div>
  
<div class="landingBtnsContainer" *ngIf="!auth.isLoggedIn()">
  <button type="button" class="btn teacherRegisterBtn" routerLink="/register/TEACHER">{{ts.l.i_am_a_teacher}}</button><br>
  <button type="button" class="btn studentRegisterBtn" routerLink="/register/STUDENT">{{ts.l.i_am_a_student}}</button>
  <div class="loginTextContainer">
      {{ts.l.already_have_account}}
      <a
        routerLink="/login"
        data-cy=sign-in>
        {{ts.l.sign_in}}
      </a>
  </div>
</div>
  
<img src="assets/img/main01-resize.png" class="bottomLeftImg" alt="An old woman telling a story by the fireplace">
<img src="assets/img/main02-resize.png" class="bottomRightImg" alt="A girl with a tablet and a cat">
<!-- img src="assets/img/main03.png" class="topRightImg" -->

<div class="catContainer">
  <img src="assets/img/main04-resize.png" class="catJumpingImg" alt="A cat jumping">
  <img src="assets/img/main05-resize.png" class="catWalkingImg" alt="A cat standing">
</div>

<!-- Footer containing logos -->
<div class="footerLanding">
  <div class="copyright">{{ts.l.copyright}}</div>
  <div class="footerLogos">
    <img class="logoImg" src="assets/img/logo_abair-compressed.png" alt="Abair logo">
    <img class="logoImg" src="assets/img/logo_S20-compressed.png" alt="20-Year Strategy for the Irish Language logo">
    <img class="logoImg" src="assets/img/logo_dept_arts_culture-compressed.png" alt="Department of Tourism, Culture, Arts, Gaeltacht, Sport and Media logo">
    <img class="logoImg" src="assets/img/logo_cogg-compressed.png" alt="An Chomhairle um Oideachas Gaeltachta & Gaelscolaíochta logo">
    <img class="logoImg" src="assets/img/logo_TCD-compressed.png" alt="Trinity College Dublin logo">  
  </div>
</div>
