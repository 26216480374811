<div class="container">
  <div class="header">
    <strong>{{ts.l.sponsors}}</strong>
  </div>
  <!-- Block of sponsor logos -->
  <div class="textBlock" style="font-size: 14pt;" innerHTML={{ts.l.sponsor_abair}}></div>
  <div class="logoContainer">
    <img class="logoImg" src="assets/img/logo_dept_arts_culture.png">
    <img class="logoImg" src="assets/img/logo_S20.png">
    <img class="logoImg" src="assets/img/logo_plean.jpg">
    <img class="logoImg" src="assets/img/logo_abair.png">
    <img class="logoImg" src="assets/img/logo_TCD.png">
  </div>
  <!-- 'Thank you' messages to some sponsors -->
  <mat-card class="sponsorCard">
    <div><b>{{ts.l.thanks_to}}</b></div>
    <div class="cardBody">
      <div innerHTML={{ts.l.sponsor_cogg}} class="cardLeft"></div>
      <div class="cardRight">
        <img src="assets/img/logo_cogg.png" class="logoCardImg" alt="An Chomhairle um Oideachas Gaeltachta & Gaelscolaíochta">
      </div>
    </div>
  </mat-card><br>
  <mat-card class="sponsorCard">
    <div class="name"><b>{{ts.l.like_to_highlight}}:</b></div>
    <div class="cardBody">
      <div innerHTML={{ts.l.sponsor_cgd}} class="cardLeft"></div>
      <div class="cardRight">
        <img src="assets/img/logo_cgd.png" class="logoCardImg" alt="Coláiste Ghaoth Dobhair">
      </div>
    </div>
  </mat-card><br>
  <div class="textBlock" innerHTML={{ts.l.interested_teachers}}></div>
  <!-- User testimonials -->
  <div class="header">
    {{ts.l.testimonials}}
  </div>
  <mat-card class="sponsorCard">
    <ul class="textBlock">
      <li class="smallTextBlock">"{{ts.l.testimonial_1}}"</li>
      <li class="smallTextBlock">"{{ts.l.testimonial_2}}"</li>
      <li class="smallTextBlock">"{{ts.l.testimonial_3}}"</li>
      <li class="smallTextBlock">"{{ts.l.testimonial_4}}"</li>
      <li class="smallTextBlock">"{{ts.l.testimonial_5}}"</li>
    </ul>
    <div class="testimonialFooter">- {{ts.l.from_students}}</div>
  </mat-card>
</div>